@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100vw;
  height: 100vh;
}

/* Placing this after tailwind imports fixes tailwind overwrite issue with button */
:where([class*='css-dev-only-do-not-override']).ant-btn-primary,
:where([type='button']).ant-btn-primary,
:where([type='submit']).ant-btn-primary {
  color: #fff;
  background-color: #e77f6e;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
